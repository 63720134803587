<template>
    <div class="discounts-list-tabs">
        <div class="tabs">
            <div v-for="(tab, key) in info.tabs" :key="key">
                <button
                    type="button"
                    class="tab"
                    :class="[info.show === key ? 'active' : '']"
                    @click="resolveHandler(tab, key)()"
                >
                    <img
                        v-if="tab.icon"
                        :src="info.show === key ? tab.icon.active : tab.icon.inactive"
                        alt=""
                    />
                    {{ tab.title }}
                    <span
                        v-if="tab.amount"
                        class="tab-amount"
                        :class="info.show == key ? 'bg-site-primary' : 'bg-gray-400'"
                        >{{ tab.amount }}</span
                    >
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'

type TabItem = {
    title: string
    icon?: {
        active: string
        inactive: string
    }
    amount?: number
    to?: string
}
type TabsListInfo = {
    tabs: Array<TabItem>
    show: string | number
}

export default defineComponent({
    name: 'MicroTabs',
    props: {
        info: {
            type: Object as PropType<TabsListInfo>,
            required: true,
        },
    },
    data() {
        return {
            dropdown: {
                show: {
                    orderby: false,
                },
            },
        }
    },
    methods: {
        currentTab(key: any) {
            this.info.show = key
        },
        handleChangeRoute(to: string) {
            if (this.$route.path !== to) this.$router.push(to)
        },
        resolveHandler(tab: TabItem, key: number) {
            return tab.to ? () => this.handleChangeRoute(tab.to || '') : () => this.currentTab(key)
        },
    },
})
</script>

<style lang="postcss" scoped>
.discounts-list-tabs {
    @apply flex justify-between border-b border-gray-300 pb-4;
    .tabs {
        @apply flex h-8 justify-center space-x-3 sm:justify-start;
        li {
            @apply block h-full;
        }
        .tab {
            @apply flex h-full cursor-pointer items-center gap-x-1 rounded-full bg-white px-5 text-base text-gray-800 hover:border-gray-300 hover:text-gray-800;
            &-amount {
                @apply mb-1 self-center rounded-full px-1.5 py-0.5 text-xs text-white;
            }
            img {
                @apply h-4 w-4 self-center;
            }
        }
        .active {
            @apply font-semibold text-site-primary shadow hover:border-site-primary hover:text-site-primary;
        }
    }
    .order-by {
        @apply flex items-center gap-1;
        .order-text {
            @apply hidden font-sans text-xs text-gray-800 sm:block md:text-lg;
        }
        .order-button {
            @apply relative flex gap-1 rounded-lg border border-gray-200 bg-white p-1.5 px-4 text-xs font-semibold text-site-primary md:text-base;
            img {
                @apply h-3 self-center;
            }
        }
    }
}
</style>
